import { createFileRoute } from '@tanstack/react-router'
import { Suspense, lazy } from 'react'

const PhoneLoginForm = lazy(() => import('../../../../components/molecules/login/phone-login-form/phone-login-form'))

export const Route = createFileRoute('/snap/_unauthenticated/login/phone')({
    component: () => (
        <Suspense fallback={null}>
            <PhoneLoginForm />
        </Suspense>
    )
})
