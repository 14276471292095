import { useSignInWithPhone } from '@shared-snap/snap/components/auth/hooks/use-sign-in'
import { useNavigate } from '@tanstack/react-router'
import firebase, { type RecaptchaVerifier } from 'app/firebase'
import { getRecaptchaVerifier } from 'app/utils/recaptcha'
import { Button } from 'components/atoms/button/button'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { PhoneInput } from 'react-international-phone'
import { prefix } from '../../../../routes/__root'
import 'react-international-phone/style.css'
import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { FormLinks } from 'components/molecules/login/form-links'
import { PhoneNumberUtil } from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

export const isPhoneValid = (phone: string) => {
    try {
        const parsedNumber = phoneUtil.parseAndKeepRawInput(phone)
        return phoneUtil.isPossibleNumber(parsedNumber) ? true : 'Invalid phone number'
    } catch (error) {
        return 'Invalid phone number'
    }
}

interface PhoneNumber {
    phoneNumber: string
}

export default function PhoneLoginForm() {
    const navigate = useNavigate()
    const {
        handleSubmit,
        control,
        formState: { isValid },
        reset
    } = useForm<PhoneNumber>({})
    const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const {
        LL: { login: loginI18n, shared, countries }
    } = useI18nContext()

    useEffect(() => {
        setRecaptchaVerifier(getRecaptchaVerifier())
        reset()
    }, [])

    const signIn = useSignInWithPhone((data: PhoneNumber & { recaptcha: RecaptchaVerifier }) => {
        return firebase.auth().signInWithPhoneNumber(data.phoneNumber, data.recaptcha)
    })

    const onSubmit = async (data: PhoneNumber) => {
        if (!recaptchaVerifier) {
            console.error('RecaptchaVerifier is not set')
            return
        }

        setIsLoading(true)

        try {
            await signIn({ ...data, recaptcha: recaptchaVerifier })
            localStorage.setItem('phoneNumber', data.phoneNumber)
            await navigate({ to: `${prefix}/login/code` })
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    if (isLoading) {
        return <UI.Loader />
    }

    return (
        <form className="flex flex-col grow justify-between" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center grow shrink-0 mb-10">
                <div className="flex flex-col gap-y-10 w-full">
                    <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{ required: shared.errorFieldIsRequired({ field: loginI18n.phoneNumber() }), validate: isPhoneValid }}
                        render={({ field, fieldState }) => (
                            <div>
                                <PhoneInput
                                    {...field}
                                    value={field.value}
                                    onChange={value => {
                                        field.onChange(value)
                                    }}
                                    inputStyle={{
                                        borderTop: 'none',
                                        borderLeft: 'none',
                                        borderRight: 'none',
                                        borderBottom: '1px solid #E5E7EB',
                                        outline: 'none',
                                        padding: '0.5rem',
                                        width: '100%'
                                    }}
                                    countrySelectorStyleProps={{
                                        buttonStyle: {
                                            borderTop: 'none',
                                            borderLeft: 'none',
                                            borderRight: 'none',
                                            borderBottom: '1px solid #E5E7EB'
                                        },
                                        dropdownStyleProps: { style: { outline: 'none' } }
                                    }}
                                    defaultCountry="is"
                                />
                                {fieldState.error && <p className="text-red-500 text-sm mt-1">{fieldState.error.message}</p>}
                            </div>
                        )}
                    />

                    <Button className="mx-auto" disabled={!isValid} type="submit">
                        {loginI18n.logInBtn()}
                    </Button>
                </div>
            </div>

            <FormLinks type="email" />
        </form>
    )
}
