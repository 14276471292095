import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useUnitsGroups } from '@shared-snap/snap/components/settings/hooks/use-units'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { ZIndex } from '@shared/css-constants'
import { Modal, ModalHeader } from 'components/atoms/modal/modal'
import { useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'

type SelectUnitModalProps = {
    isOpen: boolean
    onClose: () => void
    onSelect: (group: { value: string; label: string } | { value: string; label: string }[]) => void
    onCreate: (group: { value: string; label: string }) => void
    isMulti?: boolean
    className?: string
}

type SelectModalProps = SelectUnitModalProps & {
    items: string[]
    title: string
    placeholder: string
}

export const SelectStringModal = ({
    isOpen,
    title,
    placeholder,
    items,
    onClose,
    onSelect,
    onCreate,
    isMulti = false,
    className = ''
}: SelectModalProps) => {
    const [innerItems, setInnerItems] = useState(items.map(item => ({ value: item, label: item })))

    useEffect(() => {
        setInnerItems(items.map(item => ({ value: item, label: item })))
    }, [items])

    return (
        <Modal className={`bg-white w-[400px] rounded-lg shadow-lg -mt-[10%] ${className}`} isOpen={isOpen} onClose={onClose}>
            <ModalHeader onClose={onClose} className="pb-2 rounded-lg">
                <UI.Text size="md" color="snap-black">
                    {title}
                </UI.Text>
            </ModalHeader>
            <CreatableSelect
                className="min-w-[200px] m-4"
                autoFocus
                openMenuOnFocus
                closeMenuOnSelect={!isMulti}
                name="group"
                menuPosition="fixed"
                menuPlacement="auto"
                options={innerItems}
                isClearable
                isSearchable
                placeholder={placeholder}
                isMulti={isMulti}
                styles={{
                    container: provided => ({
                        ...provided,
                        zIndex: ZIndex.tooltip
                    }),
                    menu: provided => ({
                        ...provided,
                        zIndex: ZIndex.tooltip
                    }),
                    menuList: provided => ({
                        ...provided,
                        zIndex: ZIndex.tooltip
                    })
                }}
                onChange={selectedOption => {
                    if (selectedOption && Array.isArray(selectedOption)) {
                        onSelect(selectedOption)
                    } else if (selectedOption) {
                        onSelect(selectedOption as { value: string; label: string })
                    }

                    if (!isMulti) {
                        onClose()
                    }
                }}
                onCreateOption={(inputValue: string) => {
                    if (!inputValue.trim()) return

                    const newOption = { value: inputValue, label: inputValue }
                    onCreate(newOption)
                    setInnerItems(prev => [...prev, newOption])

                    if (!isMulti) {
                        onClose()
                    }
                }}
            />
        </Modal>
    )
}

export const SelectGroupModal = ({ isOpen, onClose, onSelect, onCreate, isMulti }: SelectUnitModalProps) => {
    const {
        LL: { settingsWeb, settings }
    } = useI18nContext()
    const groups = useUnitsGroups()

    return (
        <SelectStringModal
            isOpen={isOpen}
            title={settings.units.groupPlaceholder()}
            placeholder={settingsWeb.units.groupPlaceholder()}
            items={groups}
            onClose={onClose}
            onSelect={onSelect}
            onCreate={onCreate}
            isMulti={isMulti}
        />
    )
}
