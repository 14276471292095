import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentUser, usePermission } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useAddDailyComment } from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-add-daily-comment'
import { useAddUnitNote } from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-add-unit-note'
import {
    useAreaSummary,
    useAreaSummaryCleaningStatusColor,
    useAreaSummaryDate,
    useDailyComment,
    useExtraService,
    useIsFutureBooking,
    useLastHousekeeping,
    useUnitNote
} from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-area-summary'
import { useAreaSummaryAssign } from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-area-summary-assign'
import { useChecklist } from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-checklist'
import { useFetchNextBooking } from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-fetch-next-bookings'
import {
    activeRuleAtom,
    activitiesAtom,
    assignedAtom,
    currentTaskAtom
} from '@shared-snap/snap/components/housekeeping/area-summary/state/area-summary-state'
import { Activities } from '@shared-snap/snap/components/housekeeping/area-summary/ui/activity-row'
import { DeveloperSection } from '@shared-snap/snap/components/housekeeping/area-summary/ui/developer-info'
import { useHousekeepingOverviewDate } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/hooks/use-housekeeping-overview'
import { ChangedInfo, CreatedInfo, DueDate, IssueImage } from '@shared-snap/snap/components/issues/ui/issue'
import { PrioritySign } from '@shared-snap/snap/components/molecules/priority-sign'
import { IssueName } from '@shared-snap/snap/components/my-tasks/ui/render-task'
import { useSplitFirestoreQuery } from '@shared-snap/snap/infra/firestore-atom'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Icon } from '@shared-snap/snap/types/visuals.types'
import { isSuperUser } from '@shared-snap/snap/utils/is-super-user'
import type { IssueStruct, UserStruct } from '@shared/firestore-structs'
import { getFilteredIssuesQuery } from '@shared/issue-data'
import { Link, useRouter } from '@tanstack/react-router'
import firebaseWrapped, { asFirebase } from 'app/firebase'
import AssignedContacts from 'components/atoms/assigned-contacts'
import { IconButton } from 'components/atoms/icon-button'
import MarkdownRenderer from 'components/atoms/markdown-renderer'
import { Modal } from 'components/atoms/modal/modal'
import { QuantityLabel } from 'components/atoms/quantity-label'
import { AreaSummaryHeader } from 'components/molecules/housekeeping/area-summary-header/area-summary-header'
import CleaningScheduleSection from 'components/molecules/housekeeping/area-summary-schedule/cleaning-schedule'
import { NoteSection } from 'components/molecules/housekeeping/note-section/note-section'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { CleaningStatusSelector, OccupancySelector } from '../molecules/housekeeping/cleaning-selectors/cleaning-selectors'

interface AreaSummaryModalProps {
    areaKey: string
}

interface SelectorLayoutProps {
    label: string
    icon: Icon
}

interface InfoSectionProps {
    title: string
    icon: Icon
    infoText: string | null | undefined
}

export function AreaSummaryModal({ areaKey }: AreaSummaryModalProps) {
    const router = useRouter()
    const data = useAreaSummary(areaKey)
    const { status } = data
    const housekeepingOverview = useHousekeepingOverviewDate()
    const { date, setDate } = useAreaSummaryDate()
    useEffect(() => {
        if (housekeepingOverview.valueOf() !== date.valueOf()) {
            setDate(moment(housekeepingOverview))
        }
    }, [])

    const onClose = () => {
        const currentPath = router.state.location.pathname
        const newPath = currentPath.split('/').slice(0, -1).join('/')

        router.navigate({ to: newPath })
    }
    return (
        <Modal className={`relative w-3/4 ${status === 'loaded' ? 'max-h-[75%] h-fit' : 'h-3/4'} flex flex-col`} onClose={onClose}>
            {status === 'error' && (
                <div className="w-full h-full flex justify-center items-center">
                    <UI.ErrorMessage>{`Error loading data ${data.errors}`}</UI.ErrorMessage>
                </div>
            )}
            {status === 'loading' && (
                <div className="w-full h-full flex justify-center items-center">
                    <UI.Loader />
                </div>
            )}
            {status === 'loaded' && <Content onClose={onClose} areaKey={areaKey} />}
        </Modal>
    )
}

function Content({ onClose, areaKey }: { onClose: () => void; areaKey: string }) {
    const {
        LL: { area, shared, commentAndNotes, activities: activitiesI18n }
    } = useI18nContext()
    const {
        booking: { notes }
    } = useFetchNextBooking(asFirebase(firebaseWrapped))
    const assignedUsers = useRecoilValue(assignedAtom)
    const assign = useAreaSummaryAssign(asFirebase(firebaseWrapped))
    const activities = useRecoilValue(activitiesAtom)
    const dailyComment = useDailyComment()
    const unitNote = useUnitNote()
    const isFutureBooking = useIsFutureBooking()
    const addDailyComment = useAddDailyComment(asFirebase(firebaseWrapped))
    const addUnitNote = useAddUnitNote(asFirebase(firebaseWrapped))
    const currentUser = useCurrentUser()
    const extraService = useExtraService()

    const handleAddDailyComment = (comment: string) => {
        addDailyComment(comment)
    }
    const handleAddUnitNote = (comment: string) => {
        addUnitNote(comment)
    }

    const handleUnassignUsers = () => {
        assign([])
    }
    return (
        <>
            <AreaSummaryHeader onClose={onClose} />
            <div className="w-full p-[40px] overflow-y-scroll flex flex-col gap-8 mt-6 mb-6">
                <div className="flex items-center justify-center gap-16">
                    <SelectorLayout label={isFutureBooking ? area.nextBooking() : area.currentBooking()} icon="specta-booking-note">
                        <OccupancySelector />
                    </SelectorLayout>

                    <SelectorLayout label={area.housekeepingStatus()} icon="specta-broom">
                        <CleaningStatusSelector />
                    </SelectorLayout>
                </div>
                {assignedUsers.length > 0 && (
                    <div className="flex items-center justify-center gap-16">
                        <AssignedTo users={assignedUsers} onUnassign={handleUnassignUsers} />
                        <div className="w-1/2 display-none" />
                    </div>
                )}
                <div className="flex items-center justify-center gap-16">
                    <NoteSection
                        label={shared.dailyComment()}
                        name="dailyComment"
                        icon="specta-daily-comment"
                        handleSave={handleAddDailyComment}
                        value={dailyComment?.comment ?? ''}
                    />
                    <NoteSection
                        label={shared.unitNote()}
                        name="unitNote"
                        icon="specta-note"
                        value={unitNote ?? ''}
                        handleSave={handleAddUnitNote}
                        type="textarea"
                    />
                </div>
                <InfoSection title={commentAndNotes.bookingNotes()} icon="specta-booking-note" infoText={notes} />
                <InfoSection title={commentAndNotes.extra()} icon="specta-extras" infoText={extraService} />
                <CleaningScheduleSection />
                <LastHousekeepingSection />
                <IssuesListSection areaKey={areaKey} />
                <RulesChecklist />
                {activities.length ? (
                    <div>
                        <UI.Text weight="bold" size="md">
                            {activitiesI18n.name()}
                        </UI.Text>
                        <div className="mt-6 flex-col flex gap-3">
                            <Activities />
                        </div>
                    </div>
                ) : null}
                {isSuperUser(currentUser) && <DeveloperSection />}
            </div>
        </>
    )
}

function InfoSection({ title, icon, infoText }: InfoSectionProps) {
    if (!infoText) return

    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
                <UI.Icon icon={icon} size="md" />
                <UI.Text weight="bold" size="md">
                    {title}
                </UI.Text>
            </div>

            <MarkdownRenderer content={infoText} />
        </div>
    )
}

function RulesChecklist() {
    const {
        LL: { area }
    } = useI18nContext()
    const activeRule = useRecoilValue(activeRuleAtom)
    const { checklist } = useChecklist(asFirebase(firebaseWrapped))

    return (
        <div className="flex flex-col gap-y-[24px]">
            {activeRule && (
                <div className="flex flex-col gap-y-[5px]">
                    <UI.Text weight="bold" size="md">
                        {activeRule.name}
                    </UI.Text>
                    <UI.Text size="sm" color="snap-silver">
                        {activeRule.description}
                    </UI.Text>
                </div>
            )}
            {activeRule && checklist.length > 0 && (
                <div className="flex flex-col bg-snap-mid-silver p-[16px] gap-y-[16px] rounded-[16px] mx-[-16px]">
                    <UI.Text size="md">{area.checklist()}</UI.Text>
                    <div className="flex flex-col gap-y-[16px]">
                        <UI.List
                            items={checklist}
                            render={(item, i) => (
                                <div key={i} className="flex gap-x-[16px] items-center">
                                    <div
                                        className={`flex w-[20px] h-[20px] items-center justify-center rounded-full  ${
                                            item.checked ? 'bg-sweeply-teal' : 'border border-sweeply-light-gray'
                                        }`}>
                                        {item.checked && <UI.Icon icon="specta-checkmark" size="xs" color="white" />}
                                    </div>
                                    <UI.Text size="sm" color="snap-mid-gray">
                                        {item.name}
                                    </UI.Text>
                                </div>
                            )}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

function SelectorLayout({ label, icon, children }: React.PropsWithChildren<SelectorLayoutProps>) {
    const cleaningStatusColor = useAreaSummaryCleaningStatusColor()

    return (
        <div className="flex flex-col justify-between py-10 items-start bg-white w-1/2 rounded border xl:px-24 sm:px-10 h-[250px] border-snap-light-silver">
            <div className="flex gap-2 items-center mb-4">
                <UI.Icon icon={icon} size="md" color={cleaningStatusColor} />
                <h3 className="font-bold">{label}</h3>
            </div>
            {children}
        </div>
    )
}

function AssignedTo({ users, onUnassign }: { users: Pick<UserStruct, 'initials' | 'key' | 'name'>[]; onUnassign: () => void }) {
    const {
        LL: { shared }
    } = useI18nContext()
    const havePermission = usePermission()

    return (
        <div className="flex flex-col justify-center items-start bg-white w-[50%] rounded border p-4 border-snap-light-silver gap-6">
            <div className="flex items-center justify-between w-full">
                <h3 className="font-bold">{shared.assignedBlockHeader()}</h3>
                {havePermission('assign-housekeeping-un-assign') && (
                    <IconButton icon="specta-close" size="xs" color="snap-silver" onClick={onUnassign} />
                )}
            </div>
            <ul className="flex items-center gap-4 w-full">
                {users.map(user => (
                    <li key={user.key} className="flex items-center gap-3">
                        <UI.InitialsCircle initials={user.initials} name={user.name} size="sm" />
                        <UI.Text>{user.name}</UI.Text>
                    </li>
                ))}
            </ul>
        </div>
    )
}

function LastHousekeepingSection() {
    const {
        LL: { area }
    } = useI18nContext()
    const lastHousekeeping = useLastHousekeeping()

    if (!lastHousekeeping) return null

    const { finished, doneBy, duration } = lastHousekeeping

    return (
        <div className="flex flex-col gap-[16px]">
            <UI.Text weight="bold" size="md">
                {area.lastHousekeeping()}
            </UI.Text>
            <div className="flex flex-col gap-1">
                <UI.Text color="snap-silver" weight="normal">
                    {area.finished()}
                </UI.Text>
                <UI.Text color="snap-mid-gray">{finished}</UI.Text>
            </div>
            <div className="flex flex-col gap-1">
                <UI.Text color="snap-silver" weight="normal">
                    {area.doneBy()}
                </UI.Text>
                <ul className="flex flex-col justify-center items-start gap-4 w-full">
                    {doneBy.map(user => (
                        <li key={user.key} className="flex items-center gap-3">
                            <UI.InitialsCircle initials={user.initials} name={user.name} size="sm" />
                            <UI.Text color="snap-mid-gray">{user.name}</UI.Text>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="flex flex-col gap-1">
                <UI.Text color="snap-silver" weight="normal">
                    {area.duration()}
                </UI.Text>
                <UI.Text color="snap-mid-gray">{duration}</UI.Text>
            </div>
        </div>
    )
}

interface IssuesListSectionProps {
    areaKey: string
}

function IssuesListSection({ areaKey }: IssuesListSectionProps) {
    const {
        LL: { issues: issuesI18n, shared }
    } = useI18nContext()
    const user = useCurrentUser()
    const [issues, setIssues] = useState<IssueStruct[]>([])
    const [loaded, setLoaded] = useState(false)
    useSplitFirestoreQuery<IssueStruct>(
        issues => {
            setIssues(issues)
            setLoaded(true)
        },
        () => {
            setIssues([])
        },
        db => getFilteredIssuesQuery(db, user.organizationKey, { areaKey, statuses: ['open', 'assigned'] }),
        `Issues for area summary${areaKey}`
    )

    const [seeAllActive, setSeeAllActive] = useState(false)

    return (
        <div>
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                    <UI.Text weight="bold" size="md">
                        {issuesI18n.header()}
                    </UI.Text>
                    {loaded && <QuantityLabel quantity={issues.length} size="md" />}
                    {!loaded && <UI.Loader size={'sm'} />}
                </div>
                {issues.length > 1 && (
                    <button onClick={() => setSeeAllActive(!seeAllActive)}>
                        <UI.Text color="snap-teal" size="sm" weight="normal">
                            {seeAllActive ? shared.close() : shared.seeAll()}
                        </UI.Text>
                    </button>
                )}
            </div>
            <div>
                <UI.List
                    items={seeAllActive ? issues : issues.length > 0 ? [issues[0]] : []}
                    render={issue => {
                        const { priority, lastThumbUrl, lastItemKey, isImagesLoading, key, name, updated, assignedTo, created, startDate } =
                            issue

                        return (
                            <Link key={key} className="flex w-full gap-x-6 py-4" to="/snap/issues/$key" params={{ key: key }}>
                                <IssueImage
                                    lastThumbUrl={lastThumbUrl}
                                    isImagesLoading={isImagesLoading}
                                    lastItemKey={lastItemKey}
                                    priority={priority}
                                    Render={({ priority, src }) => (
                                        <div className="relative min-w-[105px] min-h-[105px] w-[105px] h-[105px] flex items-center justify-center border rounded">
                                            <div className="absolute -right-2 -top-2">
                                                <PrioritySign priority={priority} backgroundColor="snap-red" iconColor="white" size="sm" />
                                            </div>
                                            {src === 'specta-text-issue' ? (
                                                <i className={`${src} text-3xl`} />
                                            ) : (
                                                <img src={src} className="w-full h-full object-cover" alt="issue" />
                                            )}
                                        </div>
                                    )}
                                />
                                <div className="flex flex-col flex-1 items-start justify-between">
                                    <IssueName name={name} size="sm" />
                                    <div className="w-full flex flex-row justify-between items-center">
                                        <DueDate
                                            dueDate={startDate}
                                            renderNoDueDate={!startDate}
                                            Render={({ date, icon }) => (
                                                <div className="flex flex-row items-center gap-x-[6px]">
                                                    <UI.Icon size="xs" color="snap-red" icon={icon} />
                                                    <UI.Text color="snap-red">{moment(date).format('MMM D')}</UI.Text>
                                                </div>
                                            )}
                                        />
                                        <AssignedContacts assignedContacts={assignedTo ?? null} size="sm" />
                                    </div>
                                    <div className="w-full flex flex-row items-end justify-between">
                                        <ChangedInfo
                                            updated={updated}
                                            Render={({ text }) => (
                                                <UI.Text size="xs" color="snap-silver">
                                                    {text}
                                                </UI.Text>
                                            )}
                                        />
                                        <CreatedInfo
                                            created={created}
                                            Render={({ text }) => (
                                                <UI.Text size="xs" color="snap-silver">
                                                    {text}
                                                </UI.Text>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Link>
                        )
                    }}
                />
            </div>
        </div>
    )
}
