import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentUser } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useEditedUnit, useUnits } from '@shared-snap/snap/components/settings/hooks/use-units'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { createArea, updateArea } from '@shared/area-data'
import { useNavigate } from '@tanstack/react-router'
import firebase, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button/button'
import { Checkbox } from 'components/atoms/checkbox'
import { FieldErrorMessage } from 'components/atoms/field-error-message'
import { FieldLabel } from 'components/atoms/field-label'
import Input from 'components/atoms/input/input'
import { Modal, ModalHeader } from 'components/atoms/modal/modal'
import { useEffect, useRef, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { prefix } from 'routes/__root'
import { SelectGroupModal } from './select-group-modal'

interface UnitForm {
    name: string
    description: string
    address: string
    group: { value: string; label: string }
    displayCleaningStatus: boolean
}
export function UnitModal({ unitKey }: { unitKey: string }) {
    const editedArea = useEditedUnit(unitKey)

    const currentUser = useCurrentUser()
    const [areas, setAreas] = useUnits()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const navigate = useNavigate()

    const formRef = useRef<HTMLFormElement>(null)

    const {
        LL: { settingsWeb, settings, shared }
    } = useI18nContext()

    const defaultValues: Readonly<UnitForm> = {
        name: '',
        description: '',
        address: '',
        group: { value: '', label: '' },
        displayCleaningStatus: true
    }
    const form = useForm<UnitForm>({
        defaultValues
    })
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = form

    useEffect(() => {
        if (editedArea) {
            setValue('name', editedArea.name ?? '')
            setValue('description', editedArea.description ?? '')
            setValue('address', editedArea.address ?? '')
            setValue('group', editedArea.group ? { value: editedArea.group, label: editedArea.group } : { value: '', label: '' })
            setValue('displayCleaningStatus', editedArea.displayCleaningStatus ?? true)
        } else {
            setValue('name', '')
            setValue('description', '')
            setValue('address', '')
            setValue('group', { value: '', label: '' })
            setValue('displayCleaningStatus', true)
        }
    }, [editedArea, setValue])

    const onClose = () => navigate({ to: `${prefix}/settings/units` })

    const onSubmit = async (data: UnitForm) => {
        setIsSubmitting(true)
        const formattedValues = { ...data, group: data.group.value }

        try {
            if (unitKey) {
                await updateArea(asFirebase(firebase), unitKey, formattedValues, currentUser)
                setAreas(areas.map(area => (area.key === unitKey ? { ...area, ...formattedValues } : area)))
            } else {
                console.log('Creating new area with values:', formattedValues)
                const areaKey = await createArea(asFirebase(firebase), formattedValues, currentUser)
                setAreas([...areas, { ...formattedValues, key: areaKey, cleaningStatus: 'clean', occupancy: 'vacant' }])
            }
            onClose()
        } catch (error) {
            // TODO: Add user friendly error handling
            console.error('Failed to save unit:', error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const [isUnitSelectionOpen, setIsUnitSelectionOpen] = useState(false)

    return (
        <FormProvider {...form}>
            <Modal className="flex flex-col w-3/4 max-w-[600px] h-[90%] max-h-fit" onClose={onClose}>
                <ModalHeader onClose={onClose} className="pb-2 rounded-lg">
                    <UI.Text size="2xl" color="snap-black">
                        {unitKey ? settingsWeb.units.editUnit() : settingsWeb.units.newUnit()}
                    </UI.Text>
                </ModalHeader>
                <div className="flex flex-col grow px-6 pt-6 overflow-y-scroll">
                    <form ref={formRef} className="flex flex-col h-full justify-between" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col gap-8 mb-8 pb-[60px]">
                            <FieldLabel label={`${settingsWeb.units.nameColumn()}:`} required>
                                <Input name="name" placeholder={settings.nameFieldPlaceholder()} required register={register} />
                                {errors.name && <FieldErrorMessage message={shared.errorFieldIsRequired({ field: 'This field' })} />}
                            </FieldLabel>
                            <FieldLabel label={`${settings.units.descriptionLabel()}:`}>
                                <Input
                                    name="description"
                                    placeholder={settingsWeb.units.unitDescriptionPlaceholder()}
                                    register={register}
                                />
                            </FieldLabel>
                            <FieldLabel label={`${settings.units.addressLabel()}:`} required>
                                <Input name="address" placeholder={settings.units.addressPlaceholder()} register={register} />
                            </FieldLabel>
                            <FieldLabel horizontal label={`${settings.units.groupLabel()}:`} required>
                                <SelectGroupModal
                                    isOpen={isUnitSelectionOpen}
                                    onClose={() => setIsUnitSelectionOpen(false)}
                                    onSelect={option => {
                                        setValue('group', Array.isArray(option) ? option[0] : option)
                                    }}
                                    onCreate={option => {
                                        setValue('group', Array.isArray(option) ? option[0] : option)
                                    }}
                                />
                                <Controller
                                    defaultValue={{ value: '', label: '' }}
                                    name="group"
                                    rules={{
                                        required: shared.errorFieldIsRequired({ field: 'This field' }),
                                        validate: value => value.label.trim() !== ''
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <UI.Pressable
                                                onPress={() => setIsUnitSelectionOpen(true)}
                                                type="button"
                                                className={`justify-end w-fit shadow-none cursor-pointer border ${field.value?.label !== '' ? 'border-snap-light-blue text-snap-light-blue font-semibold' : 'border-gray-400 text-gray-400'} flex items-center justify-between min-h-[38px] px-3 py-2 rounded-md cursor-pointer`}>
                                                {field.value?.label || settingsWeb.units.groupPlaceholder()}
                                            </UI.Pressable>
                                        </>
                                    )}
                                />
                            </FieldLabel>
                            <div className="flex items-center gap-x-2">
                                <Checkbox name="displayCleaningStatus" />
                                <label htmlFor="displayCleaningStatus">{settings.units.display()}</label>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="absolute right-0 bottom-0 flex justify-end gap-4 justify-end mr-4 mb-4">
                    {isSubmitting ? (
                        <div>
                            <UI.Loader />
                        </div>
                    ) : (
                        <>
                            <Button className="bg-snap-silver" type="button" onClick={() => shared.cancel()}>
                                {shared.cancel()}
                            </Button>
                            <Button type="button" onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>
                                {shared.save()}
                            </Button>
                        </>
                    )}
                </div>
            </Modal>
        </FormProvider>
    )
}
