import { type CountryCode, parsePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'

import type { Features } from './feature-toggles'
import type { AreaStruct, IssueStruct, OrgStruct, TaskStruct, UserStruct } from './firestore-structs'
import * as c from './txt-constants'

interface PageIsNotExist {
    totalCount: number
    pageNumber: number
    pageSize: number
}
export function pageIsNotExist({ totalCount, pageNumber, pageSize }: PageIsNotExist) {
    const pagesQuantity = Math.ceil(totalCount / pageSize)
    return pageNumber > pagesQuantity
}

// / Validate E164 phoneNumber format
export function validPhoneNumber(phoneNumber: string) {
    return parsePhoneNumberFromString(phoneNumber)
}

export function cleanHashTags(str: string) {
    if (!str) return ''
    const regexp = /#([^\s]*)/g
    const postText = str.replace(regexp, '')
    return postText.trim()
}

export function getCategoryOrHashtagDescriptionForNotificationBody({
    task,
    org,
    useLegacyHashtags
}: { task: TaskStruct; org: OrgStruct; useLegacyHashtags: boolean }): string {
    if (useLegacyHashtags) {
        return getHashTags(task.name).join(' ')
    } else {
        return getDynamicCategoryDescription(task.categories, org.categories)
    }
}

export function getDynamicCategoryDescription(taskCategories: TaskStruct['categories'], orgCategories: OrgStruct['categories']): string {
    const selectedCategoriesForTask = taskCategories ?? {}

    const categoryStringList = Object.keys(selectedCategoriesForTask).flatMap(key => {
        if (!orgCategories) {
            // edge case when categories are not defined
            return selectedCategoriesForTask[key]
        }

        const otherCategoryList = Object.keys(selectedCategoriesForTask)
            .filter(otherKey => key !== otherKey)
            .flatMap(otherKey => selectedCategoriesForTask[otherKey])
        const otherCategorySet = new Set(otherCategoryList)

        const resultItems: string[] = []

        for (const categoryItem of selectedCategoriesForTask[key]) {
            if (otherCategorySet.has(categoryItem)) {
                // include parent category name for clarity of clashing categoryItems
                const parentCategoryName = orgCategories.find(
                    category => category.categoryItems.includes(categoryItem) && category.id === key
                )?.name

                resultItems.push(parentCategoryName ? `${parentCategoryName}:${categoryItem}` : categoryItem)
            } else {
                resultItems.push(categoryItem)
            }
        }

        return resultItems
    })

    return categoryStringList.join(', ')
}

export function userIsSubscribedToTask({
    task,
    org,
    user,
    useLegacyHashtags
}: { task: TaskStruct; org: OrgStruct; user: UserStruct; useLegacyHashtags: boolean }): boolean {
    if (user == null || task == null) {
        return false
    }

    if (useLegacyHashtags === false) {
        return userIsSubscribedToTaskWithCategory(task, user)
    }
    if (!user.issueHashtags) {
        return false
    }
    const issueHashtags = getHashTags(task.name)

    return Array.isArray(user.issueHashtags) && issueHashtags.some(hashtag => user.issueHashtags.includes(hashtag))
}

export function userIsSubscribedToTaskWithCategory(task: TaskStruct, user: UserStruct): boolean {
    if (user == null || task == null) {
        return false
    }

    const taskCategories = task.categories
    const userIssueCategories = user.issueCategories

    if (!taskCategories) {
        return false
    }

    if (!userIssueCategories) {
        return false
    }

    return Object.keys(userIssueCategories).some(key => {
        const userCategories = userIssueCategories[key] ?? []

        return userCategories.some(userCategory => {
            return taskCategories[key]?.includes(userCategory) ?? false
        })
    })
}

export function hashTagsToString(hashTags: string[]) {
    let result = ''
    hashTags.forEach(hashTag => {
        result += hashTag + ' '
    })
    if (result !== '') {
        result = result.substring(0, result.length - 1)
    }
    return result
}

export function getHashTags(string?: string) {
    if (!string) return []

    let hashTags = null
    let i
    let len
    let word
    const words = string.split(/[\s\r\n]+/)
    hashTags = []
    for (i = 0, len = words.length; i < len; i++) {
        word = words[i]
        if (word.indexOf('#') === 0) {
            hashTags.push(word)
        }
    }
    return hashTags
}

export function removeHashtag(str: string, tagToRemove: string) {
    if (tagToRemove.startsWith('#')) {
        let result = cleanHashTags(str)
        const hashTagsInStr = getHashTags(str)
        hashTagsInStr.forEach(tagInStr => {
            if (tagToRemove !== tagInStr) {
                result += ' ' + tagInStr
            }
        })
        return result.trim()
    }
    return str
}

export function sortByName(a: string, b: string) {
    return a.localeCompare(b, 'en', { sensitivity: 'base' })
}

export function sortTimeStampDescending(a: number, b: number) {
    if (a == null || b == null) return 0
    let result = 0

    let aTimeStamp = a
    let bTimeStamp = b

    if (aTimeStamp === undefined) aTimeStamp = 0
    if (bTimeStamp === undefined) bTimeStamp = 0

    result = bTimeStamp - aTimeStamp
    return result
}

export function sortTimeStampAscending(a: number, b: number) {
    if (a == null || b == null) return 0
    let result = 0

    let aTimeStamp = a
    let bTimeStamp = b

    if (aTimeStamp === undefined) aTimeStamp = 0
    if (bTimeStamp === undefined) bTimeStamp = 0

    result = aTimeStamp - bTimeStamp
    return result
}

export function shortenLongName(str: string, limit: number) {
    if (!str) {
        return ''
    }
    const dots = '...'
    if (str.length > limit) {
        str = str.substr(0, limit) + dots
    }

    return str
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getInitials(name: string) {
    if (!name) return 'N/A'
    // const initials = Array.prototype.map.call(name.split(' '), x => x.substring(0, 1).toUpperCase()).join('')
    const initials = name
        .replace(/[^A-Za-z0-9À-ÿ ]/gi, '') // taking care of accented characters as well
        .replace(/ +/gi, ' ') // replace multiple spaces to one
        .split(/ /) // break the name into parts
        .reduce((acc, item) => acc + item[0], '') // assemble an abbreviation from the parts
        .concat(name.substr(1)) // what if the name consist only one part
        .concat(name) // what if the name is only one character
        .concat(name) // what if the name is only one character
        .substr(0, 3) // get the first three characters an initials
        .toUpperCase()
    return initials
}

export function getPlural(text: string, count: number) {
    if (count === 1) {
        return text
    }
    return text + 's'
}

export function filterUsersByAreaGroup(area: AreaStruct, users: UserStruct[]) {
    return users.forEach(user => {
        return (
            (user.areaGroups && user.areaGroups.includes(area.group as string)) ||
            (user.areaGroups && user.areaGroups.length === 1 && user.areaGroups[0] === 'All')
        )
    })
}

export function removeQuotes(string: string) {
    let outputString = string.replace(/“/g, '')
    outputString = outputString.replace(/”/g, '')
    outputString = outputString.replace(/"/g, '')
    return outputString
}

export function searchSet(issue: IssueStruct) {
    let issueString = null
    if (!issue.area) {
        issueString = issue.name + ' ' + issue.issueNumber
    } else {
        issueString =
            issue.issueNumber +
            ' ' +
            issue.name +
            ' ' +
            issue.area.name +
            ' ' +
            issue.area.description +
            ' ' +
            issue.area.address +
            ' ' +
            issue.area.group +
            ' ' +
            issue.changerName
    }
    return issueString
}

export function areaSearchSet(area: Partial<AreaWithTask> & { assignedTo?: Partial<UserStruct>[] }) {
    let assignedToNames = ''
    let assignedToInitials = ''
    if (area.task && Array.isArray(area.task.assignedTo)) {
        assignedToNames = area.task.assignedTo.map(n => n.name).join(' ')
        assignedToInitials = area.task.assignedTo.map(i => i.initials).join(' ')
    }
    if (area.assignedTo && Array.isArray(area.assignedTo)) {
        assignedToNames = area.assignedTo.map(n => n.name).join(' ')
        assignedToInitials = area.assignedTo.map(i => i.initials).join(' ')
    }
    let cleaningStatus = area.cleaningStatus ? area.cleaningStatus : area.status
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
        cleaningStatus = 'unclean'
    } else if (
        cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB ||
        cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE ||
        cleaningStatus === c.CLEANING_STATUS_NO_SERVICE
    ) {
        cleaningStatus = cleaningStatus.replace(/-/g, ' ')
    } else if (!cleaningStatus) {
        cleaningStatus = 'unknown'
    }

    return (
        area.name +
        ' ' +
        area.group +
        ' ' +
        area.description +
        ' ' +
        area.note +
        ' ' +
        cleaningStatus +
        ' ' +
        area.occupancy +
        ' ' +
        assignedToNames +
        ' ' +
        assignedToInitials
    )
}

export function normalizeString(string: string) {
    return string
}

export function search(value: string, searchDomain: IssueStruct[], searchSetFunction: ((issue: IssueStruct) => string) | null = null) {
    let matches = null
    if (value === '' || value === ' ' || value === '  ' || value === '   ') {
        matches = searchDomain
    } else {
        let searchString = removeQuotes(value)
        searchString = normalizeString(searchString)
        matches = searchDomain.filter(issue => {
            let issueString = null
            if (searchSetFunction) {
                issueString = searchSetFunction(issue)
            } else {
                issueString = searchSet(issue)
            }
            issueString = normalizeString(issueString)

            return issueString.toLocaleLowerCase().search(searchString.toLocaleLowerCase()) >= 0
        })
    }
    matches.sort((a, b) => sortTimeStampDescending(a.updated, b.updated))
    return matches
}

export type AreaWithTask = AreaStruct & { task: TaskStruct; status: string }

function isNumeric(num: number) {
    return !isNaN(num)
}

/*
    TODO - rewrite this mess
*/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function sortAreas(areas) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const areasGroupSorted = areas.sort((a, b) => {
        return a.group.toLowerCase().localeCompare(b.group.toLowerCase(), 'en', { numeric: true, sensitiviy: 'base' })
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const areasMap = {}

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    areasGroupSorted.forEach(area => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (areasMap[area.group]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            areasMap[area.group].push(area)
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            areasMap[area.group] = [area]
        }
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const areasMapKeys = Object.keys(areasMap)

    areasMapKeys.forEach(groupKey => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        areasMap[groupKey].sort((a, b) => {
            const aArr = a.name.match(/[a-zA-Z]+|[0-9]+/g)
            const bArr = b.name.match(/[a-zA-Z]+|[0-9]+/g)

            if (isNumeric(aArr[0]) && isNumeric(bArr[0])) {
                return aArr[0] - bArr[0] || a.name.localeCompare(b.name)
            } else {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            }
        })
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let areasToReturn = []

    Object.values(areasMap).forEach(a => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        areasToReturn = areasToReturn.concat(a)
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return areasToReturn
}

export function isPhoneNumberValid(phoneNumber: string, countryCode: CountryCode) {
    // avoiding getting errors/exceptions from libphonenumber-js library
    if (!phoneNumber || phoneNumber.length < 6) return false
    if (!countryCode) return false

    const parsedNumber = parsePhoneNumber(phoneNumber, countryCode)
    const isPossible = parsedNumber.isPossible() && parsedNumber.isValid()

    return !(!isPossible || parsedNumber.country !== countryCode)
}

export const removeUndefinedValues = <T extends object>(obj: T): T => {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([_, value]) => value !== undefined)
            .map(([k, v]) => [k, v !== null && typeof v === 'object' && !Array.isArray(v) ? removeUndefinedValues(v) : v])
    ) as T
}

export const arraysAreEqual = (arr1: string[], arr2: string[]) => {
    const set1 = new Set(arr1)
    const set2 = new Set(arr2)
    if (set1.size !== set2.size) return false
    for (const value of set1) {
        if (!set2.has(value)) return false
    }
    return true
}
